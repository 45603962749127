<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pb-3">
        <v-card-title>
          Create extensions
          <a class="ml-3" style="font-size: 0.8em;" href="https://docs.google.com/spreadsheets/d/1uMxSNNB6zPTYNFIG3gOT5OBmoxm4E1-lCbq7knG1wCA/edit#gid=23437196" target="_blank"><v-icon>help</v-icon></a>
        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col>
              <p>Create extensions by selecting an account and the extension type, and then pasting extension details into the field below.</p>
            </v-col>
          </v-row>

          <v-form ref="form">
            <v-row>

              <v-col md="4">
                <BingAccountAutoComplete
                  v-model="selectedDestinationAccountID"
                  label="Destination account" 
                  @changed="destinationAccountChanged"
                ></BingAccountAutoComplete>
              </v-col>

              <v-col md="4">
                <BingAccountAutoComplete
                  v-model="selectedBaseAccountID"
                  label="Optional: base Bing account" 
                  :mandatory="false" 
                  @changed="baseAccountChanged"
                ></BingAccountAutoComplete>

                <v-autocomplete v-model="selectedExtensionType" :items="extensionTypes" label="Extension type" prepend-icon="extension" @change="extensionTypeChanged()">
                </v-autocomplete>

                <v-btn style="min-width: 165px;" class="mt-4" color="success" :loading="fillingFromBase" :disabled="!selectedDestinationAccountID || !selectedBaseAccountID" @click="fillFromBingBase()">Fill from base</v-btn>
                <span class="ml-2" style="color: red;" v-show="!selectedDestinationAccountID && selectedBaseAccountID">Select destination account</span>
              </v-col>

              <v-col md="4">
                <GoogleAccountAutoComplete
                  v-model="selectedBaseGoogleAccountID"
                  label="Optional: base Google account" 
                  :mandatory="false" 
                  @changed="baseGoogleAccountChanged"
                ></GoogleAccountAutoComplete>

                <v-autocomplete v-model="selectedExtensionType" :items="extensionTypes" label="Extension type" prepend-icon="extension" @change="extensionTypeChanged()">
                </v-autocomplete>

                <v-btn style="min-width: 165px;" class="mt-4" color="success" :loading="fillingFromGoogle" :disabled="!selectedDestinationAccountID || !selectedBaseGoogleAccountID" @click="fillFromGoogleBase()">Fill from Google base</v-btn>
                <span class="ml-2" style="color: red;" v-show="!selectedDestinationAccountID && selectedBaseGoogleAccountID">Select destination account</span>
              </v-col>

            </v-row>

            <v-row>
              <v-col md="12">
                <v-text-field style="font-size: 0.9em;" dense :placeholder="extensionsHeader" disabled></v-text-field>
                <v-textarea v-model="extensionsContent" clearable dense rows="6" hint="Paste extension details here. Columns should be TAB separated, and values should be pipe (|) separated." style="background-color: lightyellow;"
                  required :rules="[v => !!v || 'Extension details are required']"  @change="extensionsContentChanged">
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12">
                <v-btn color="success" :loading="validating" @click="create(true)">Validate</v-btn>
                <v-fade-transition mode="out-in">
                  <v-btn color="green darken-1" text v-show="showValid">Valid</v-btn>
                </v-fade-transition>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12">
                <v-alert v-model="showErrorAlert" color="error" border="left" elevation="2" colored-border dismissible class="mt-4 ml-2 mr-2" icon="error" style="white-space: pre-line;">{{errorMsg}}</v-alert>
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col md="12" :hidden="!validated || parsedSitelinks.length == 0">
              <v-simple-table class="mt-4">
                <thead>
                  <tr class="pb-0">
                    <th class="extTypeCol">Type</th>
                    <th>Display text</th>
                    <th>Description 1</th>
                    <th>Description 2</th>
                    <th>Final URL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in parsedSitelinks" :key="item.siteLink_displayText+item.siteLink_description1+item.siteLink_description2+item.siteLink_finalUrl">
                    <td>{{item.extension_type}}</td>
                    <td>{{item.siteLink_displayText}}</td>
                    <td>{{item.siteLink_description1}}</td>
                    <td>{{item.siteLink_description2}}</td>
                    <td>{{item.siteLink_finalUrl}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col md="12" :hidden="!validated || parsedCallouts.length == 0">
              <v-simple-table class="mt-4">
                <thead>
                  <tr class="pb-0">
                    <th class="extTypeCol">Type</th>
                    <th>Callout text</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in parsedCallouts" :key="item.callout_texts_str">
                    <td>{{item.extension_type}}</td>
                    <td>{{item.callout_text}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col md="12" :hidden="!validated || parsedStrucSnips.length == 0">
              <v-simple-table class="mt-4">
                <thead>
                  <tr class="pb-0">
                    <th class="extTypeCol">Type</th>
                    <th>Language</th>
                    <th>Header</th>
                    <th>Localized header</th>
                    <th>Values</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in parsedStrucSnips" :key="item.structuredSnippet_header+item.structuredSnippet_values_str">
                    <td>{{item.extension_type}}</td>
                    <td>{{item.structuredSnippet_language}}</td>
                    <td>{{item.structuredSnippet_header}}</td>
                    <td>{{item.structuredSnippet_localized_header}}</td>
                    <td>{{item.structuredSnippet_values_str}}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" :hidden="!validated">
              <v-btn class="mt-0" color="primary" :loading="creating" @click="create(false)">Create</v-btn>
              <v-fade-transition mode="out-in">
                <v-btn color="primary darken-1" text v-show="showCreated">Created</v-btn>
              </v-fade-transition>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>

    </v-col>
  </v-row>

</template>

<script>
import ccMixin from '@/mixins/contentCreator'
import formsMixin from '@/mixins/forms'

export default {
  name: 'BingExtensions',

  data () {
    return {
      extensionsContent: '',
      parsedSitelinks: [],
      parsedCallouts: [],
      parsedStrucSnips: [],
      extensionsHeader: 'Type   Data   ...',
      selectedDestinationAccountID: 0,
      extensionTypes: ['-- All --', 'SitelinkAdExtension', 'CalloutAdExtension', 'StructuredSnippetAdExtension'],
      selectedExtensionType: '-- All --',
      selectedBaseAccountID: 0,
      selectedBaseGoogleAccountID: 0,
      fillingFromGoogle: false,
      validating: false,
      fillingFromBase: false,
      creating: false,
      lsSelectedDestinationAccount: 'contentCreator_selectedDestinationAccountBing',
      lsSelectedBaseAccount: 'contentCreator_selectedBaseAccountBing',
      lsSelectedBaseGoogleAccount: 'contentCreator_selectedBaseAccount',
      lsContent: 'contentCreator_ExtensionsContentBing',
      lsExtensionType: 'contentCreator_ExtensionTypeBing',
      validated: false,
      showValid: false,
      showCreated: false,
      showErrorAlert: false,
      errorMsg: '',
    }
  },

  mixins: [ccMixin, formsMixin],

  computed: {
    baseEndpoint () {
      return '/a/bing/accounts/' + this.selectedBaseAccountID + '/content-creator/extensions?type=' + this.selectedExtensionType
    },

    baseGoogleEndpoint () {
      return '/a/bing/accounts/' + this.selectedDestinationAccountID + '/content-creator/google-extensions?type=' + this.selectedExtensionType + '&google_account_id=' + this.selectedBaseGoogleAccountID + '&xper_page=2000'
    },

    destEndpoint () {
      return '/a/bing/accounts/' + this.selectedDestinationAccountID + '/content-creator/extensions'
    },
  },

  components: {
    BingAccountAutoComplete: () => import('@/components/autocompletes/BingAccountAutoComplete.vue'),
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
  },

  methods: {
    baseAccountChanged: function () {
      if (!this.selectedBaseAccountID) {
        this.selectedBaseAccountID = 0
      }

      // save to local storage
      localStorage.setItem(this.lsSelectedBaseAccount, JSON.stringify(this.selectedBaseAccountID))
    },

    baseGoogleAccountChanged: function () {
      if (!this.selectedBaseGoogleAccountID) {
        this.selectedBaseGoogleAccountID = 0
      }

      localStorage.setItem(this.lsSelectedBaseGoogleAccount, JSON.stringify(this.selectedBaseGoogleAccountID))
    },

    create (validateOnly = true) {
      this.showErrorAlert = false
      if (!this.$refs.form.validate()) {
        return false
      }

      var action = 'creating'
      var showVar = 'showCreated'
      if (validateOnly) {
        this.validated = false
        action = 'validating'
        showVar = 'showValid'
      }

      this[action] = true

      var splitLines = this.extensionsContent.split('\n')
      var obj = {
        'lines': splitLines,
        'validate_only': validateOnly
      }
      var body = JSON.stringify(obj)

      this.$http.post(this.destEndpoint, body).then(resp => {
        if (resp.data.data.error_msgs) {
          this.errorMsg = this.forms_getValidationDisplay(resp.data.data.error_msgs, 'error(s)')
          this.showErrorAlert = true
          this.resetParsed()
        } else {
          if (resp.data.data.parsed_extensions) {
            this.parsedSitelinks = resp.data.data.parsed_extensions.filter(v => v.extension_type == 'SitelinkAdExtension')
            this.parsedCallouts = resp.data.data.parsed_extensions.filter(v => v.extension_type == 'CalloutAdExtension')
            this.parsedStrucSnips = resp.data.data.parsed_extensions.filter(v => v.extension_type == 'StructuredSnippetAdExtension')
            this.validated = true
            this[showVar] = true
            this.timer = setTimeout(() => { this[showVar] = false }, this.cc_buttonDisplayMS)
          } else {
            this.resetParsed()
          }
        }
      })
      .catch(errResp => {
        this.validated = false
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.destEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this[action] = false)
    },

    destinationAccountChanged: function () {
      localStorage.setItem(this.lsSelectedDestinationAccount, JSON.stringify(this.selectedDestinationAccountID))
    },

    extensionsContentChanged: function () {
      this.validated = false
      this.showValid = false
      this.showErrorAlert = false

     // save to local storage
      localStorage.setItem(this.lsContent, JSON.stringify(this.extensionsContent))
    },

    extensionTypeChanged: function () {
      this.validated = false
      this.showErrorAlert = false

     // save to local storage
      localStorage.setItem(this.lsExtensionType, JSON.stringify(this.selectedExtensionType))
    },

    fillFromBingBase: function () {
      if (this.selectedBaseAccountID == 0) {
        return
      }

      this.extensionsContent = ''
      this.validated = false
      this.showErrorAlert = false
      this.fillingFromBase = true

      // fetch DB data for selected campaign
      var myURL = this.baseEndpoint

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {

          // put the DB data into tab-separated format depending on extension type
          var content = ''
          resp.data.data.forEach(v => {
            content += v.extension_type
            switch (v.extension_type) {
              case "SitelinkAdExtension":
                content += '\t' + v.siteLink_displayText
                content += '\t' + v.siteLink_description1
                content += '\t' + v.siteLink_description2
                content += '\t' + v.siteLink_finalUrl
                content += '\n'
                break
              case "CalloutAdExtension":
                content += '\t' + v.callout_text
                content += '\t'
                content += '\t'
                content += '\t'
                content += '\n'
                break
              case "StructuredSnippetAdExtension":
                content += '\t' + v.structuredSnippet_language
                content += '\t' + v.structuredSnippet_header
                content += '\t' + v.structuredSnippet_values_str
                content += '\t'
                content += '\n'
                break
            }
          })

          if (content) {
            this.extensionsContent = content
          } else {
            this.extensionsContent = 'No extensions found of type ' + this.selectedExtensionType
          }
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.fillingFromBase = false)
    },

    fillFromGoogleBase: function () {
      if (this.selectedBaseGoogleAccountID == 0) {
        return
      }

      this.extensionsContent = ''
      this.validated = false
      this.showErrorAlert = false
      this.fillingFromGoogle = true

      // fetch DB data for selected campaign
      var myURL = this.baseGoogleEndpoint

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {

          // put the DB data into tab-separated format depending on extension type
          var content = ''
          resp.data.data.forEach(v => {
            content += v.extension_type
            switch (v.extension_type) {
              case "SitelinkAdExtension":
                content += '\t' + v.siteLink_displayText
                content += '\t' + v.siteLink_description1
                content += '\t' + v.siteLink_description2
                content += '\t' + v.siteLink_finalUrl
                content += '\n'
                break
              case "CalloutAdExtension":
                content += '\t' + v.callout_text
                content += '\t'
                content += '\t'
                content += '\t'
                content += '\n'
                break
              case "StructuredSnippetAdExtension":
                content += '\t' + v.structuredSnippet_language
                content += '\t' + v.structuredSnippet_header
                content += '\t' + v.structuredSnippet_values_str
                content += '\t'
                content += '\n'
                break
            }
          })

          if (content) {
            this.extensionsContent = content
          } else {
            this.extensionsContent = 'No extensions found of type ' + this.selectedExtensionType
          }
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.fillingFromGoogle = false)
    },

    resetParsed() {
      this.parsedSitelinks = []
      this.parsedCallouts = []
      this.parsedStrucSnips = []
    }
  },

  created: function () {
    // if present, use local selected accounts
    if (localStorage.getItem(this.lsSelectedDestinationAccount)) {
      this.selectedDestinationAccountID = JSON.parse(localStorage.getItem(this.lsSelectedDestinationAccount))
      this.destinationAccountChanged()
    }
    if (localStorage.getItem(this.lsSelectedBaseAccount)) {
      this.selectedBaseAccountID = JSON.parse(localStorage.getItem(this.lsSelectedBaseAccount))
      this.baseAccountChanged()
    }
    if (localStorage.getItem(this.lsSelectedBaseGoogleAccount)) {
      this.selectedBaseGoogleAccountID = JSON.parse(localStorage.getItem(this.lsSelectedBaseGoogleAccount))
      this.baseGoogleAccountChanged()
    }

    // if present, use ext type
    if (localStorage.getItem(this.lsExtensionType)) {
      this.selectedExtensionType = JSON.parse(localStorage.getItem(this.lsExtensionType))
    }
    this.extensionTypeChanged()

    // if present, use content
    if (localStorage.getItem(this.lsContent)) {
      this.extensionsContent = JSON.parse(localStorage.getItem(this.lsContent))
    }
  },

}
</script>

<style scoped>
.extTypeCol {
  width: 200px;
}
</style>